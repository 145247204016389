<template>
  <v-container class="pa-10 mb-5">
    <v-row justify="center">

   
    <v-card class="pa-5"  max-width="1000px" align-center>
      <v-card-title>
        <span class="headline">Company Info</span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="name"
                label="Name of your company"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>

            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="address"
                label="Address of your company"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="email"
                label="Company Email"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="phone"
                label="Phone Number"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="help_center"
                label="Hot Line"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="About"
                label="About"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="policy"
                label="Company Policy "
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="terms_condition"
                label="Company Tearms And condition"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="slogan"
                label="Company Slogan"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" sm="6" md="5">
              <template>
                <v-file-input
                  v-model="logo"
                  label="Company Logo"
                  filled
                  prepend-icon="mdi-camera"
                ></v-file-input>
              </template>
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <template>
                <v-file-input
                  v-model="icon"
                  label="Icon"
                  filled
                  prepend-icon="mdi-camera"
                ></v-file-input>
              </template>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="Facebook"
                label="Facebook"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="twitter"
                label="twitter "
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="linkedin"
                label="linkedin"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                v-model="youtube"
                label="youtube "
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#854FFF" @click="save">Save</v-btn>
        <v-btn color="red" @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
     </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    name: "",
    address: "",
    icon: "",
    email: "",
    phone: "",
    help_center: "",
    policy: "",
    About: "",
    terms_condition: "",
    slogan: "",
    Facebook: "",
    twitter: "",
    linkedin: "",
    youtube: "",
    logo: "",
  }),

  methods: {
    save() {
      let rpolicy = this.policy.split(",");
      let terms = this.terms_condition.split(",");

      let formData = new FormData();

      formData.append("icon", this.icon);
      formData.append("logo", this.logo);

      formData.append("name", this.name);
      formData.append("address", this.address);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("help_center", this.help_center);
      formData.append("About", this.About);
      formData.append("slogan", this.slogan);
      formData.append("Facebook", this.Facebook);
      formData.append("twitter", this.twitter);
      formData.append("youtube", this.youtube);
      formData.append("terms_condition", terms);
      formData.append("policy", rpolicy);

      axios
        .post("site/info/", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
   
          this.$router.push("/dashboard/pages/companyinfo");
        });
    },
    close() {
      this.$router.push("/dashboard/pages/companyinfo");
    },
  },
};
</script>

<style>
</style>